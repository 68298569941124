import {graphql, StaticQuery, navigate} from 'gatsby'
import React, {useState, useEffect} from 'react'
import Layout from '../components/layout'

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      description
      keywords
      logo {
        asset {
          url
          title
        }
      }
    }

    mainNavigation: sanityNavigation(_id: {regex: "/(drafts.|)1ecb46c8-ef46-4394-bf9b-c2d8dcafa142/"}) {
      links {
        title
        url
        region
      }
    }

    footerNavigation: sanityNavigation(_id: {regex: "/(drafts.|)d39aaad0-1ab2-4457-b8ce-dd5989da4572/"}) {
      links {
        title
        url
        region
      }
    }
  }
`

function LayoutContainer (props) {
  const {data, errors} = props
  
  if (errors) {
    return (
    <Layout>
        <GraphQLErrorList errors={errors} />
    </Layout>
    )
  }


  const [showNav, setShowNav] = useState(false)
  function handleShowNav () {
    setShowNav(true)
  }
  function handleHideNav () {
    setShowNav(false)
  }


  return (
    <StaticQuery
      query={query}
      render={data => {
        // console.log('--> layout data', data)
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
          )
        }
        return (
          <Layout
            {...props}
            showNav={showNav}
            siteTitle={data.site.title}
            onHideNav={handleHideNav}
            onShowNav={handleShowNav}
            logo={data.site.logo.asset.url}
            mainNav={data.mainNavigation ? data.mainNavigation.links : []}
            footerNav={data.footerNavigation ? data.footerNavigation.links : []}
          />
        )
      }}
    />
  )

}

export default LayoutContainer
