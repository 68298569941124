import React, {useEffect, useState} from 'react'
import Header from './header'
import Helmet from 'react-helmet'
import { withPrefix, Link, navigate } from "gatsby"
import {isUserInRegion} from '../services/auth'
import { useLocation } from '@reach/router';

// folio theme
// <!-- Plugins CSS -->
import '../folio_template/assets/vendor/font-awesome/css/all.min.css'
import '../folio_template/assets/vendor/bootstrap-icons/bootstrap-icons.css'
import '../folio_template/assets/vendor/animate/animate.min.css'
import '../folio_template/assets/vendor/tiny-slider/tiny-slider.css'
import '../folio_template/assets/vendor/aos/aos.css'
import '../folio_template/assets/vendor/glightbox/css/glightbox.css'

// <!-- Theme CSS -->
import '../folio_template/assets/css/style.css'


import '../styles/layout.css'
import styles from './layout.module.css'

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle, logo, mainNav, footerNav}) => {

  let userRegion = 'APAC,EMEA,LAC,NA'
  const location = useLocation();

  return(
    <>
      <div>
        <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} logo={logo} mainNav={mainNav} />
        <div className={styles.content}>{children}</div>
      </div>

      <footer className="bg-footer position-relative">
        <div className="container">       
          <div className="row py-4">
            <div className="flex-column flex-lg-row d-flex justify-content-center justify-content-lg-between align-items-lg-center w-100 text-primary-hover">

              <div className="color-font-base d-flex justify-content-center mt-3 mb-0 m-lg-0">© Burger King Corporation 2021</div>

              <ul className="nav justify-content-center mt-3 m-lg-0">
                {
                  footerNav && !['/auth/login', '/auth/login/'].includes(location.pathname) &&
                  footerNav.map((item, i) => {
                    if(isUserInRegion(userRegion, item.region)){
                      return <li key={i} className="nav-item"><Link className="nav-link" to={item.url || "#"}>{item.title}</Link></li>
                    }
                  })
                }
              </ul>           
                          
            </div>
          </div>
          
        </div>
      </footer>

      <Helmet>
          <script src={withPrefix('bootstrap.bundle.min.js')} type="text/javascript" />
          <script src={withPrefix('tiny-slider.js')} type="text/javascript" />
          <script src={withPrefix('functions.js')} type="text/javascript" />
          <script src={withPrefix('accessibe.js')} type="text/javascript" />
      </Helmet>
    </>
  )
}

export default Layout
