export async function fetchDatafromAPI(apiEndpoint, data) {
    const response = await fetch(apiEndpoint, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        //credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        //referrerPolicy: "no-referrer", // no-referrer, *client
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      }
    );  
    // console.log('log: fetchDatafromAPI -> response', response);
    return await response.json(); // parses JSON response into native JavaScript objects
} 