import {fetchDatafromAPI} from '../lib/apiHelper'
import ACTIONS from './actions'
import Cookies from 'js-cookie'
import {navigate} from 'gatsby'
import { Base64 } from 'js-base64'

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () => {
    const userStr = isBrowser() ? window.localStorage.getItem("dwbkUser") : null
    if (!userStr) {
        return null
    }

    var CryptoJS = require("crypto-js")
    var bytes  = CryptoJS.AES.decrypt(userStr, 'userKey')
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    // console.log('--> de: ', decryptedData)
    return decryptedData

}


export const setUser = user => {
    // for display go to dashboard link
    // if is setted by native login: should pass user.userGroup = 'franchise' because accessRequest form make for only that group
    // if is setted by sso: user.userGroup = th group that return from sso


    var CryptoJS = require("crypto-js")
    var encrypted = CryptoJS.AES.encrypt(JSON.stringify(user), 'userKey').toString()
    // console.log('--> en: ', encrypted)
    if(isBrowser()){
        window.localStorage.setItem("dwbkUser", encrypted)
    }

}

export const logout = () => {
    // delete token from Sanity?
    deleteOktaSession()

    // logout native
    setUser({})
    if(isBrowser()){
        Cookies.remove('okta-user')
        window.localStorage.removeItem("okta-user")

        window.localStorage.removeItem("dwbkUser")
    }
    navigate('/auth/login')
}

const deleteOktaSession = async () => {
    let id = Cookies.get('okta-user')
    if(id && id != undefined) id = Base64.decode(id)

    // let apiEndpointOkta = 'https://j43jfzh1qh.execute-api.us-east-1.amazonaws.com/prod/api'
    let apiEndpointOkta = process.env.NATIVE_USER_API_ENDPOINT
    let dataOkta = {
        action: ACTIONS.DELETE_OKTA_SESSION,
        values: id
    }
    let responseOkta = await fetchDatafromAPI(apiEndpointOkta, dataOkta)
    return responseOkta
}

export const isUserInRegion = (_userRegion, contentRegion) => {
    // if(!_userRegion || !contentRegion) return false

    let regionArr = contentRegion ? contentRegion.split(',') : []
    let userRegion = _userRegion ? _userRegion.split(',') : ''
    let region = regionArr.filter(x => userRegion.includes(x))

    if(region.length > 0) return true
    return false
}

export const isClientPath = () => {
    let generalPaths = [`/auth/login`, `/auth/request-access`, `/auth/reset-password`, `/auth/change-password`, `/404/`]
    //   let isClientPath = isBrowser() ? generalPaths.includes(window.location.pathname) : false
    let isClientPath = false

    if(isBrowser()){
        let clientPage = generalPaths.find(item => window.location.pathname.includes(item))
        if(clientPage){
            isClientPath = true
        }
    }

    if(isClientPath && isBrowser()){
        window.localStorage.removeItem("dwbkUser")
    }

    return isClientPath
}


export const isLoggedIn = async () => {
    if(isClientPath()) return

    if(isBrowser()){
        // check okta data response first time: data from middleware
        const urlParams = new URLSearchParams(window.location.search);
        if(urlParams.has('user')){
            const _user = urlParams.get('user')
            Cookies.set('okta-user', _user)
            navigate('/')
        }
    }

    // return false
    // const id = Base64.decode(Cookies.get('okta-user')); // okta
    let id = Cookies.get('okta-user')
    if(id && id != undefined) id = Base64.decode(id)
    
    const user = getUser() // native
    if(!user && !id) return null


    // let id = 'e-okta-nichapon-didwisade-ofitechnology-com'; // get from cookie -> okta-user
    // let apiEndpointOkta = 'https://j43jfzh1qh.execute-api.us-east-1.amazonaws.com/prod/api'
    let apiEndpointOkta = process.env.NATIVE_USER_API_ENDPOINT
    let dataOkta = {
        action: ACTIONS.CHECK_OKTA_SESSION,
        values: id
    }

    let responseOkta = await fetchDatafromAPI(apiEndpointOkta, dataOkta)
    if(responseOkta && responseOkta.body){
        // console.log('---> okta: ', responseOkta.body)
        if(responseOkta.body.isLoggedIn){
            let userInfo = responseOkta.body.user

            delete userInfo.sessionIndex

            userInfo.isOktaUser = true
            // userInfo.region = 'APAC,EMEA,LAC,NA'

            // chaged and map region on one place here prevent to change the existing code
            let _region = ''
            if(['North America', 'NA'].includes(userInfo.rbiRegion)){
                _region = 'NA'
            }
            else if(['Latin America', 'LAC'].includes(userInfo.rbiRegion)){
                _region = 'LAC'
            }
            else if(['EMEA'].includes(userInfo.rbiRegion)){
                _region = 'EMEA'
            }
            else if(['Asia Pacific', 'APAC'].includes(userInfo.rbiRegion)){
                _region = 'APAC'
            }
            else if(['Corporate'].includes(userInfo.rbiRegion)){
                _region = 'APAC,EMEA,LAC,NA'
            }
            userInfo.region = _region

            window.localStorage.setItem("okta-user", JSON.stringify(userInfo))
            // console.log('---> get okta: ', JSON.parse(window.localStorage.getItem('okta-user')))
            return userInfo
        }
        else{
            // delete cookie -> okta user && delete localstorage
            Cookies.remove('okta-user')
            window.localStorage.removeItem("okta-user")
        }
    }


    //======== native user
    try {
        // let apiEndpoint = 'https://j43jfzh1qh.execute-api.us-east-1.amazonaws.com/prod/api'
        let apiEndpoint = process.env.NATIVE_USER_API_ENDPOINT
        let data = {
            action: ACTIONS.CHECK_LOGIN_SESSION,
            values: user.loginToken
        }

        let response = await fetchDatafromAPI(apiEndpoint, data)
        // console.log('---> response double check login: ', response)

        if(response && response.body === true){
            return user
        }
        else{
            // console.log("Credentail is not correct, please check it.")
            if(isBrowser()){
                window.localStorage.removeItem("dwbkUser")
            }
            return null
        }

    } catch (error) {
        // console.log("Fetch data fail.")
        if(isBrowser()){
            window.localStorage.removeItem("dwbkUser")
        }
        return null
    }

}