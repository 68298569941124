// ACTIONS
export default {
    REQUEST_ACCESS: "requestAccess",
    LOGIN: "login",
    RESET_PASSWORD_REQUEST: "resetPasswordRequest",
    RESET_PASSWORD: "resetPassword",
    RESET_PASSWORD_MEMBER: "resetPasswordMember",
    CHECK_LOGIN_SESSION: "checkLoginSession",
    CHECK_OKTA_SESSION: "checkOktaSession",
    DELETE_OKTA_SESSION: "deleteOktaSession"
}