import {graphql, Link, navigate} from 'gatsby'
import React, {useState, useEffect, useRef} from 'react'
import Icon from './icon'
import {cn} from '../lib/helpers'
import {Nav, Navbar} from 'react-bootstrap'
import { FaSearch } from 'react-icons/fa'
import {logout, isBrowser} from '../services/auth'
import userGroups from '../services/userGroups'
import { useDispatch, useSelector } from "react-redux"
 
import styles from './header.module.css'
import './header.module.css'

const ADMIN_URL = 'http://designwithbk-adminbucket.s3-website-us-east-1.amazonaws.com/'

const Header = ({onHideNav, onShowNav, showNav, siteTitle, logo, mainNav}) => {

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.userInfo)
  const searchTerm = useSelector(state => state.searchTerm)

  // console.log('-----------> user info on header: ', userInfo)
  useEffect(() => {
    if(searchInput.current) {
      searchInput.current.value = searchTerm
      searchInputMobile.current.value = searchTerm
    }
  }, [searchTerm])

  const logOut = (e) => {
    e.preventDefault()
    dispatch({ type: 'SET_USER', payload: null })
    logout()
  }


  let searchInput = useRef(null)
  let searchInputMobile = useRef(null)

  const onSearchClick = (device) => {
    let value = device === 'desktop' ? searchInput.current.value : searchInputMobile.current.value
    // console.log('---> search: ', value)
    dispatch({type: 'SET_SEARCH', payload: value})
    navigate('/search')
    if( device === 'mobile' ){
      onHideNav()
    }
  }

  let userName = 'User'
  if(userInfo){
    if(userInfo.isOktaUser){
      // userName = userInfo.name
      userName = userInfo.FirstName ? (userInfo.FirstName + ' ') : ''
      userName += userInfo.LastName || ''
    }
    else{
      userName = userInfo.firstname ? (userInfo.firstname + ' ') : ''
      userName += userInfo.lastname || ''
    }
  }
  
  let landingPages = [`/site`, `/building`, `/decor`, `/signage`, `/site/`, `/building/`, `/decor/`, `/signage/`]
  // let isLandingPage = landingPages.find(item => window.location.pathname.includes(item))
  let isLandingPage = isBrowser() ? landingPages.find(item => window.location.pathname === item) : false

  return (
    <div>
      {/* top header */}
      <div className='root container position-relative' >

        <div className={styles.wrapper}>
          <div className={styles.branding}>
            <Link to='/' className="p-0" style={{zIndex:99}}>
              <img src={logo} title='design with bk logo' alt='design with bk logo' className={styles.logo} />
            </Link>
          </div>


          {/* for mobile menu */}
          {
            userInfo &&
            (<button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
              <Icon symbol='hamburger' />
            </button>)
          }

          <nav className={cn(styles.nav, showNav && styles.showNav)}>
            {/* user links */}
            {
              userInfo &&
              <div className="p-2">
                <div style={{textAlign:'right', color: 'rgb(244,235,220)'}}>
                  Welcome, <Link to={!userInfo.isOktaUser ? '/member/change-password' : '#'} style={{textTransform:'capitalize'}} >{userName}</Link> |{' '}
                  {
                    // (userInfo && userInfo.rbiUserType != userGroups.Franchisee) &&
                    (userInfo && userInfo.groups === 'DWBK Admin') && 
                    <><a href={ADMIN_URL}>Dashboard</a> |{' '}</>
                  }
                  <Link to="#" onClick={logOut}>Sign Out</Link>
                </div>

                <div className="col-12 mt-2">
                    <div className="input-group">
                        <input id="search-input-mobile" ref={searchInputMobile} type="text" className="form-control" placeholder="Search term" aria-label="Search input" aria-describedby="search-button" style={{height: 38}} />
                        <div className="input-group-append">
                            <button id="search-button" onClick={() => onSearchClick('mobile')} className="btn btn-primary d-flex align-items-center p-2" style={{height: 38}}><FaSearch size={22} /></button>
                        </div>
                    </div>
                </div>
              </div>
            }
            
            {/* menu list */}
            <ul>
              {
                mainNav ?
                mainNav.filter(item => item.url != '/').map((item, i) => {
                  // let bgColor = isBrowser() ? (window.location.pathname === item.url && 'rgb(214, 35, 0)') : 'rgb(43, 2, 0)'
                  let newPath = isBrowser() ? (window.location.pathname.endsWith('/') ? window.location.pathname.slice(0, -1) : window.location.pathname) : '';
                  let bgColor = newPath === item.url ? 'rgb(214, 35, 0)' : 'rgb(43, 2, 0)'

                  return <li key={i} style={{listStyle:'none', backgroundColor:bgColor}}><Link className="nav-link" to={item.url || "#"}>{item.title}</Link></li>
                })
                : ''
              }
            </ul>
          </nav>

        </div>

        {
          userInfo &&
          <div className="container position-absolute col-12 d-none d-sm-flex justify-content-sm-end align-items-end flex-column pt-1" style={{top:0, right:0}}>
            <div style={{textAlign:'right'}}>
              Welcome, <strong><Link to={!userInfo.isOktaUser ? '/member/change-password' : '#'} style={{textTransform:'capitalize'}} >{userName}</Link></strong> |{' '}
              {
                // (userInfo && userInfo.rbiUserType != userGroups.Franchisee) && 
                (userInfo && userInfo.groups === 'DWBK Admin') && 
                <><strong><a href={ADMIN_URL}>Dashboard</a></strong> |{' '}</>
              }
              <strong><Link to="#" onClick={logOut}>Sign Out</Link></strong>
            </div>

            <div className="col-4 col-md-3 mt-2" style={{right:0}}>
                <div className="input-group">
                    <input id="search-input-desktop" ref={searchInput} type="text" className="form-control" placeholder="Search term" aria-label="Search input" aria-describedby="search-button" style={{height: 38}} />
                    <div className="input-group-append">
                        <button id="search-button" onClick={() => onSearchClick('desktop')} className="btn btn-primary d-flex align-items-center p-2" style={{height: 38}}><FaSearch size={22} /></button>
                    </div>
                </div>
            </div>
          </div>
        }

      </div>


      {/* Desktop menu */}
      <div id="navbar" className={styles.mainNav} >
        <div className='container' style={{minHeight:'42px'}} >
          <ul className="d-flex justify-content-around align-items-center p-0" >
            {
              mainNav ?          
              mainNav.map((item, i) => {
                if(item.url != '/'){
                  if(userInfo){
                    let newPath = isBrowser() ? (window.location.pathname.endsWith('/') ? window.location.pathname.slice(0, -1) : window.location.pathname) : '';
                    let bgColor = newPath === item.url ? 'rgb(214, 35, 0)' : 'rgb(43, 2, 0)'

                    return <li key={i} style={isLandingPage ? {listStyle:'none', textAlign:'center', width:'100%', /*lineHeight:'52px',*/ backgroundColor:bgColor} : {listStyle:'none', textAlign:'center', width:'100%', backgroundColor:'rgb(43, 2, 0)'}}><Link to={item.url}>{item.title}</Link></li>
                  }
                }
                else{
                  // logo in middle

                  // if(!isLandingPage){
                  //   return <li key={i} className='px-2' style={userInfo ? {listStyle:'none', background:'#FFF', position:'relative', left:'4px'} : {listStyle:'none', background:'#FFF', margin:'0 auto', maxHeight: 52}}>
                  //     <Link to={item.url} style={{color: '#F58138', textAlign: 'center', position: 'relative', bottom: 8}}>
                  //       <div style={{fontSize: '1.25em', lineHeight: '1.1em'}}>DESIGN</div>
                  //       <div style={{lineHeight: '0.5em'}}>WITH BK</div>
                  //     </Link>
                  //   </li>
                  // }
                  
                }
              })
              : ''
            }
          </ul>
        </div>    
      </div>

    </div>
  )
}

export default Header
